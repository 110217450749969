import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import {
    getOrderReportByMonth,
    getOrderReportByStatus,
    getOrderReportByYear,
    downloadOrderReport
} from "../../state/actions";
import Select from "react-select";
import moment from "moment";
import { Dialog,
    DialogContent,
    DialogTitle,
    Button,
    FormControl,
    InputLabel,
    DialogActions,
    CircularProgress,
    Typography } from "@mui/material"
import Box from "@mui/material/Box";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
    getCustomers
  } from "../../state/actions/rider.actions";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from "recharts";

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

const orderStatus = [
    "New",
    "Started",
    "Completed",
    "Cancelled"
]

const dataReportByYear = [
    {
        name: "2022",
        year: 6,
        pv: 2400,
        amt: 2400,
    },
    {
        name: "2023",
        year: 8,
        pv: 1398,
        amt: 2210,
    },
    {
        name: "2024",
        year: 10,
        pv: 9800,
        amt: 2290,
    },
    {
        name: "2025",
        year: 6,
        pv: 3908,
        amt: 2000,
    },
    {
        name: "2026",
        year: 7,
        pv: 4800,
        amt: 2181,
    },
    {
        name: "2027",
        year: 9,
        pv: 3800,
        amt: 2500,
    },
    {
        name: "2028",
        year: 12,
        pv: 4300,
        amt: 2100,
    },
];

const colors = ["#2E93fA", "#66DA26", "#546E7A", "#E91E63", "#FF9800"];

const OrderReportByMonth = () => {
    const reportByMonth = useSelector((state) => state.orders.reportByMonth);
    // const reportByYear = useSelector((state) => state.orders.reportByYear);
    console.log("reportByMonth", reportByMonth);
    // console.log("reportByYear :>> ", reportByYear);
    const aa = reportByMonth.map((a) => a.key);
    const bb = reportByMonth.map((b) => b.value);
    console.log("aa :>> ", aa);
    console.log("bb", bb);
    const [options, setOptions] = useState({
        chart: {
            id: "basic-bar",
        },
        xaxis: {
            // categories: [
            //     "Jan",
            //     "Feb",
            //     "Mar",
            //     "Apr",
            //     "May",
            //     "Jun",
            //     "Jul",
            //     "Aug",
            // ],
            categories: [...months],
            labels: {
                minHeight: 30,
                maxHeight: 30,
            },
        },
        fill: {
            opacity: 1,
        },

        colors: ["#c05c54"],
        plotOptions: {
            bar: {
                horizontal: true,
                // columnWidth: '45%',
                borderRadius: 4,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
        },
    });
    // const [series, setSeries] = useState([
    //     {
    //         name: "Orders",
    //         data: [254, 232, 375, 482, 543, 214, 100],
    //     },
    // ]);
    const [series, setSeries] = useState([
        {
            name: "Orders",
            // data: [...bb],
            data: [0, 0, 0, 0, 0, 0, 0, 0, ...bb, 0, 0, 0],
        },
    ]);

    function getMonth(monthKey) {
        switch (monthKey) {
            case 1:
                return "Jan";
            case 2:
                return "Feb";
            case 3:
                return "Mar";
            case 4:
                return "Apr";
            case 5:
                return "May";
            case 6:
                return "Jun";
            case 7:
                return "Jul";
            case 8:
                return "Aug";
            case 9:
                return "Sep";
            case 10:
                return "Oct";
            case 11:
                return "Nov";
            case 12:
                return "Dec";
            default:
                return "Jan";
        }
    }

    return (
        <div className="app">
            <div className="row">
                <div style={{ width: "100%" }} className="mixed-chart">
                    <Chart options={options} series={series} type="area" />
                </div>
            </div>
        </div>
    );
};

const OrderReportByStatus = () => {
    const reportByStatus = useSelector((state) => state.orders.reportByStatus);

    const dat = [
        {
            key: "10",
            value: 5,
        },
        {
            key: "0",
            value: 1,
        },
    ];
    console.log("dat :>> ", dat);
    // console.log("reportByStatus", reportByStatus === dat);
    return (
        <BarChart
            width={400}
            height={300}
            data={reportByStatus}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="key" />
            <YAxis />
            <Tooltip />
            <Legend />
            {/* <Bar dataKey="pv" fill="#8884d8" /> */}
            <Bar dataKey="value" fill="#c05c54" />
        </BarChart>
    );
};

const OrderOverview = () => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState("2022-09-01");
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [customer, setCustomer] = useState();
  const [options, setOptions] = useState([]);
  const customers = useSelector((state) => state.riders.customers);
  const isDownload = useSelector((state) => state.orders.download);
  const [openDropOff, setOpenDropOff] = useState(false);
    const OrderReportByYear = () => {
        const reportByYear = useSelector((state) => state.orders.reportByYear);
        console.log("reportByYear", reportByYear);
        // const [dataReportByYear, setDataReportByYear] = useState([...reportByYear]);
        // const data = [
        //     {
        //         key: "2021",
        //         value: 6,
        //     },
        //     {
        //         key: "2022",
        //         value: 7,
        //     },
        //     {
        //         key: "2023",
        //         value: 9,
        //     },
        //     {
        //         key: "2024",
        //         value: 9,
        //     },
        //     {
        //         key: "2025",
        //         value: 10,
        //     },
        //     {
        //         key: "2026",
        //         value: 12,
        //     },
        //     {
        //         key: "2027",
        //         value: 10,
        //     },
        // ];
        const [dataReportByYear, setDataReportByYear] = useState(reportByYear);
        return (
            <BarChart
                width={500}
                height={300}
                data={reportByYear}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="key" />
                <YAxis />
                <Tooltip />
                <Legend />
                {/* <Bar dataKey="pv" fill="#8884d8" /> */}
                <Bar dataKey="value" fill="#c05c54" />
            </BarChart>
        );
    };
    const handleOpenDropff = () => {
        setOpenDropOff(true)
      }
      const handleCloseDropOff = () => {
        setOpenDropOff(false)
      }
    useEffect(() => {
        dispatch(getCustomers()).then((res) => {
          console.log('resCust', res)
          const custSorted = res.sort((a, b) => {
            const nameA = a.firstName?.toLowerCase();
            const nameB = b.firstName?.toLowerCase();
            if(nameA < nameB) { return -1; }
            if(nameA > nameB) { return 1; }
            return 0;
          })
          const custs = custSorted.map(c => ({
            label: c.companyName ? c.firstName + " " + c.lastName + " ( " + c.companyName + " )" : c.firstName + " " + c.lastName,
            value: c.id
          }))
          setOptions(custs)
          console.log('options', options)
        })
    }, [dispatch]);
    // dispatch(getOrders());
    // const auth = useState((state) => state.auth);
    // console.log("auth", auth);

    useEffect(() => {
        dispatch(getOrderReportByMonth());
        dispatch(getOrderReportByYear());
        dispatch(getOrderReportByStatus());
    }, [dispatch]);

    const handleNameChange = (data) => {
        console.log('selected', data)
        setCustomer(data)
      }

    const handleExportOrders = (e) => {
        e.preventDefault();
        const o = {
          id: customer ? customer.value : null,
          start: startDate,
          end: endDate
        };
        console.log("o :>>", o);
        dispatch(downloadOrderReport(o));
      };
    return (
        <>
            <NavBar />
            <SideBar />
            <div className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card card-block card-stretch card-height">
                                <div className="card-header d-flex justify-content-between flex-wrap">
                                    <div className="header-title">
                                        <h4 className="card-title mb-1">
                                            Order Stastistics
                                        </h4>
                                        {/* <span className="mb-0">
                                            03 Mar,2021 - 27 Mar,2022
                                        </span> */}
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div id="layout-2-chart-01">
                                        <OrderReportByMonth />
                                    </div>
                                    <Link
                                        to="/orders"
                                        className="btn btn-primary rounded-small mt-4"
                                    >
                                        View Full Report
                                    </Link>
                                    &nbsp;&nbsp;
                                    <Link
                                        to="/manualorders"
                                        className="btn btn-primary rounded-small mt-4"
                                    >
                                        View Manual Report
                                    </Link>
                                     &nbsp;&nbsp;
                                     <button
                                        class="btn btn-primary rounded-small mt-4"
                                        onClick={handleOpenDropff}
                                        >
                                        Export Order
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5">
                            <div className="card card-block card-stretch card-height">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">
                                            Orders Status Survey
                                        </h4>
                                    </div>
                                </div>
                                <div className="card-body text-center">
                                    <div id="hospital-chart-05">
                                        <OrderReportByStatus />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card card-block card-stretch card-height">
                                <div className="card-header d-flex justify-content-between flex-wrap">
                                    <div className="header-title">
                                        <h4 className="card-title mb-1">
                                            Order Yearly Stastistics
                                        </h4>
                                    </div>
                                    {/* <div className="card-header-toolbar d-flex align-items-center">
                                        <div className="dropdown">
                                            <span
                                                className="dropdown-toggle dropdown-bg  p-2 "
                                                id="dropdownMenuButton3"
                                                data-toggle="dropdown"
                                            >
                                                Lagos State
                                                <i className="ri-arrow-down-s-line"></i>
                                            </span>
                                            <div
                                                className="dropdown-menu dropdown-menu-right"
                                                aria-labelledby="dropdownMenuButton3"
                                            >
                                                <a
                                                    className="dropdown-item"
                                                    href="#"
                                                >
                                                    Cross River
                                                </a>
                                                <a
                                                    className="dropdown-item"
                                                    href="#"
                                                >
                                                    Enugu
                                                </a>
                                                <a
                                                    className="dropdown-item"
                                                    href="#"
                                                >
                                                    Kano
                                                </a>
                                                <a
                                                    className="dropdown-item"
                                                    href="#"
                                                >
                                                    Oyo
                                                </a>
                                                <a
                                                    className="dropdown-item"
                                                    href="#"
                                                >
                                                    Sokoto
                                                </a>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div name="RCS Generate">
          <Dialog open={openDropOff} onClose={handleCloseDropOff} maxWidth="md" fullWidth>
            <Box
              style={{ marginTop: "10px", marginBottom: "10px" }}
              sx={{
                maxWidth: "lg",
                display: "flex",
              }}
            >
              <Button style={{}} onClick={handleCloseDropOff} color="error">
                <KeyboardBackspaceIcon />
              </Button>
              <DialogTitle style={{ paddingLeft: "0" }}>
                Export Order
              </DialogTitle>
            </Box>
            <DialogContent>
              <Box>
                <div className="">
                <div className="mb-3">
                <InputLabel id="demo-select-small-label">Customer</InputLabel>
                          <Select
                          className="col-7 col-md-8 col-lg-9 px-0"
                          options={options}
                          value={customer}
                          isSearchable={true}
                          isClearable={true}
                          onChange={handleNameChange}
                          />
                    </div>
                  <div className="mb-3">
                  <InputLabel id="demo-select-small-label">Start Date</InputLabel>
                  <input
                    className="col-6 col-sm-7 col-xl-8 form-control form-control-sm"
                    name="startDate"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                  <InputLabel id="demo-select-small-label">End Date</InputLabel>
                  <input
                    className="col-6 col-sm-7 col-xl-8 form-control form-control-sm"
                    name="endDate"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </div>
              </Box>
            </DialogContent>
            <DialogActions>
              {isDownload === "loading" ? (
                <Button
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "700",
                    border: "none",
                  }}
                  variant="outlined"
                  color="error"
                >
                  <CircularProgress />
                </Button>
              ) : (
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700" }}
                  variant="outlined"
                  color="error"
                  onClick={handleExportOrders}
                >
                  Export
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
                </div>
            </div>
        </>
    );
};

export default OrderOverview;
