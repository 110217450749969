import {
  Box,
  Dialog,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TableSortLabel,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import React, { useEffect, useState, useCallback } from "react";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import { useDispatch, useSelector } from "react-redux";
import { getManualOrders, cancelOrder, resetOrder } from "../../state/actions";
import { suggestAddress, getAddressDetail } from "../../state/actions";
import moment from "moment";
import UseMap from "../../helpers/GoogleMap";
import { ToastContainer } from "react-toastify";
import { styled, alpha } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";
import { visuallyHidden } from "@mui/utils";
import { id } from "date-fns/locale";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function getNestedProperty(obj, path) {
  const pathArray = path.split('.');
  return pathArray.reduce((acc, key) => (acc && acc[key] !== 'undefined' ? acc[key] : undefined), obj);
}

function descendingComparator(a, b, orderBy) {
  let valueA = getNestedProperty(a, orderBy);
  let valueB = getNestedProperty(b, orderBy);

  if (typeof valueA === 'string' && typeof valueB === 'string') {
    valueA = valueA.toUpperCase();
    valueB = valueB.toUpperCase();
  }

  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "orderNo", numeric: false, disablePadding: true, label: "ORDER NO" },
  { id: "customer.firstName", numeric: false, disablePadding: false, label: "CUSTOMER" },
  {
    id: "pickup",
    numeric: false,
    disablePadding: false,
    label: "PICKUP LOCATION",
  },
  { id: "orderDate", numeric: false, disablePadding: false, label: "DATE" },
  { id: "orderPrice", numeric: false, disablePadding: false, label: "PRICE" },
  { id: "type", numeric: false, disablePadding: false, label: "PAYMENT TYPE" },
  { id: "status", numeric: false, disablePadding: false, label: "STATUS" },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ whiteSpace: "nowrap" }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const OrderList = () => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders.allOrders);
  const isLoading = useSelector((state) => state.orders.status);
  console.log("orders :>>", orders);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchParam, setSearchParam] = useState("");
  const [dateFilter, setDateFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [paymentTypeFilter, setPaymentTypeFilter] = useState(null);
  const [openViewDetail, setOpenViewDetail] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filterMode, setFilterMode] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    dispatch(getManualOrders());
  }, []);

  const filteredOrders = useCallback(() => {
    if (
      !searchParam &&
      !dateFilter &&
      statusFilter === null &&
      paymentTypeFilter === null
    ) {
      return orders;
    }

    return orders.filter((order) => {
      // Convert the search parameter to lowercase for case-insensitive search
      const searchTerm = searchParam.toLowerCase();

      const containsSearchTerm = Object.values(order).some((value) => {
        if (typeof value === "object" && value !== null) {
          return Object.values(value).some((nestedValue) =>
            String(nestedValue).toLowerCase().includes(searchTerm)
          );
        }
        return String(value).toLowerCase().includes(searchTerm);
      });

      // Date filter
      const dateMatches =
        !dateFilter || moment(order.orderDate).isSame(dateFilter, "day");

      // Status Filter
      const statusMatches =
        statusFilter === null || order.status === statusFilter;

      // Payment type filter
      const paymentTypeMatches =
        !paymentTypeFilter || order.type === paymentTypeFilter;

      return (
        containsSearchTerm && dateMatches && statusMatches && paymentTypeMatches
      );
    });
  }, [orders, searchParam, dateFilter, statusFilter, paymentTypeFilter]);

  const closeFilter = () => {
    setFilterMode(null);
    setDateFilter(null);
    setStatusFilter(null);
    setPaymentTypeFilter(null);
  };

  const handleCancelOrder = (id) => {
    dispatch(cancelOrder(id));
    console.log("cancelOrder :>>", selectedOrder);
    window.location.reload();
  };

  const handleResetOrder = (id) => {
    dispatch(resetOrder(id));
    console.log("resetOrder :>>", selectedOrder);
    window.location.reload();
  };

  const handleCloseViewDetail = () => {
    setOpenViewDetail(false);
  };

  const [selectedOrder, setSelectedOrder] = useState({});

  const selectOrder = (order) => {
    setSelectedOrder(order);
    console.log("selectedOrder :>> ", order);
    setOpenViewDetail(true);
  };

  function orderStatus(status) {
    switch (status) {
      case 0:
        return <span className="badge badge-secondary">New</span>;
      case 1:
        return <span className="badge badge-info">Accepted</span>;
      case 2:
        return <span className="badge badge-warning">Awaiting Pickup</span>;
      case 5:
        return <span className="badge badge-light">Started</span>;
      case 10:
        return <span className="badge badge-success">Completed</span>;
      case 11:
        return <span className="badge badge-danger">Cancelled</span>;
      case 55:
        return <span className="badge badge-warning">Hold</span>;
      default:
        return <span className="badge badge-secondary">New</span>;
    }
  }

  function destinationStatus(status) {
    switch (status) {
      case 0:
        return <span className="badge badge-info">New</span>;
      case 5:
        return <span className="badge badge-light">Started</span>;
      case 10:
        return <span className="badge badge-success">Completed</span>;
      default:
        return <span className="badge badge-info">New</span>;
    }
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const search = (e) => {
    const { value } = e.target;
    setSearchParam(value);
  };

  function cancelOrders(status) {
    switch (status) {
      case 0:
        return (
          <button
            onClick={() => handleCancelOrder(selectedOrder.id)}
            className="button btn btn-sm button-icon bg-secondary"
          >
            Cancel
          </button>
        );
      case 1:
        return (
          <button
            onClick={() => handleCancelOrder(selectedOrder.id)}
            className="button btn btn-sm button-icon bg-secondary"
          >
            Cancel
          </button>
        );
      case 2:
        return (
          <button
            onClick={() => handleCancelOrder(selectedOrder.id)}
            className="button btn btn-sm button-icon bg-secondary"
          >
            Cancel
          </button>
        );
      case 5:
        return (
          <button
            onClick={() => handleCancelOrder(selectedOrder.id)}
            className="button btn btn-sm button-icon bg-secondary"
          >
            Cancel
          </button>
        );
      case 55:
        return (
          <>
            <button
              onClick={() => handleResetOrder(selectedOrder.id)}
              className="button btn btn-sm button-icon bg-primary mr-1"
            >
              Reset
            </button>
            <button
              onClick={() => handleCancelOrder(selectedOrder.id)}
              className="button btn btn-sm button-icon bg-secondary"
            >
              Cancel
            </button>
          </>
        );
      case 10:
        return;
      case 11:
        return;
      default:
        return (
          <button
            onClick={() => handleCancelOrder(selectedOrder.id)}
            className="button btn btn-sm button-icon bg-secondary"
          >
            Cancel
          </button>
        );
    }
  }

  function estimatedValue(status) {
    switch (status) {
      case 0:
        return `₦ 
                 ${(selectedOrder?.orderPrice?.maxValue).toFixed(2)}`;
      case 10:
        return selectedOrder?.orderPrice.actualValue > 0
          ? "₦" +
              (selectedOrder?.orderPrice?.actualValue)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : `₦ 
        ${(selectedOrder?.orderPrice?.maxValue).toFixed(2)}`;
      default:
        return `₦
        ${(selectedOrder?.orderPrice?.maxValue).toFixed(2)}`;
    }
  }

  function paymentType(payment) {
    switch (payment) {
      case 0:
        return "Prepaid";
      case 1:
        return "POD";
      default:
        return "Prepaid";
    }
  }

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("orderDate");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredOrders(), getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [filteredOrders, order, orderBy, page, rowsPerPage]
  );

  const estimatedTime = selectedOrder.estimatedTime / 60;

  return (
    <>
      <NavBar />
      <SideBar />

      <div className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title d-flex justify-content-between">
                    <h4 className="card-title">Orders List With Manual Pickup Address</h4>
                  </div>
                  <div>
                    <FilterListIcon
                      aria-controls={open ? "demo-customized-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      variant="contained"
                      disableElevation
                      onClick={handleClick}
                    />
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={() => setFilterMode(0)} disableRipple>
                        Filter By Date
                      </MenuItem>
                      <MenuItem onClick={() => setFilterMode(1)} disableRipple>
                        Filter By Status
                      </MenuItem>
                      <MenuItem onClick={() => setFilterMode(2)} disableRipple>
                        Filter By Payment Type
                      </MenuItem>
                    </StyledMenu>
                  </div>
                </div>
                <div className="card-body">
                  <div>
                    {isLoading === "loading" ? (
                      <div class="text-center">
                        <div class="spinner-border text-danger" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                        <p className="small">Fetching Orders...</p>
                      </div>
                    ) : orders.length > 0 ? (
                      <>
                        <div className="d-flex flex-row-reverse">
                          <div className="col-4">
                            {filterMode === 0 && (
                              <>
                                <Close
                                  style={{ float: "right" }}
                                  onClick={() => closeFilter()}
                                />
                                <div className="form-group">
                                  <label for="dateFilter">Select Date</label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    value={dateFilter}
                                    onChange={(e) =>
                                      setDateFilter(e.target.value)
                                    }
                                  />
                                </div>
                              </>
                            )}
                            {filterMode === 1 && (
                              <>
                                <Close
                                  style={{ float: "right" }}
                                  onClick={() => closeFilter()}
                                />
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Status
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={statusFilter}
                                    label="Age"
                                    onChange={(e) =>
                                      setStatusFilter(e.target.value)
                                    }
                                  >
                                    <MenuItem value={null}>All</MenuItem>
                                    <MenuItem value={0}>New</MenuItem>
                                    <MenuItem value={1}>Accepted</MenuItem>
                                    <MenuItem value={2}>
                                      Awaiting Pickup
                                    </MenuItem>
                                    <MenuItem value={5}>Started</MenuItem>
                                    <MenuItem value={10}>Completed</MenuItem>
                                    <MenuItem value={11}>Cancelled</MenuItem>
                                    <MenuItem value={55}>Hold</MenuItem>
                                  </Select>
                                </FormControl>
                              </>
                            )}
                            {filterMode === 2 && (
                              <>
                                <Close
                                  style={{ float: "right" }}
                                  onClick={() => closeFilter()}
                                />
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Payment Type
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={paymentTypeFilter}
                                    label="Age"
                                    onChange={(e) =>
                                      setPaymentTypeFilter(e.target.value)
                                    }
                                  >
                                    <MenuItem value={null}>All</MenuItem>
                                    <MenuItem value={0}>Prepaid</MenuItem>
                                    <MenuItem value={1}>POD</MenuItem>
                                  </Select>
                                </FormControl>
                              </>
                            )}
                          </div>
                        </div>
                        <Box>
                          <div className="form-group">
                            <label className="form-label">Search</label>
                            <input
                              type="text"
                              onChange={search}
                              className="form-control form-control-sm"
                            />
                          </div>
                        </Box>
                        <TableContainer>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-labelledby="tableTitle"
                          >
                            <EnhancedTableHead
                              order={order}
                              orderBy={orderBy}
                              onRequestSort={handleRequestSort}
                              rowCount={rowsPerPage.length}
                            />
                            <TableBody>
                              {visibleRows?.map((order, index) => (
                                <TableRow hover tabindex={-1} key={index}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      padding: "0.2",
                                      margin: "0 !important",
                                    }}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {order.orderNo}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      padding: "0.2",
                                      margin: "0 !important",
                                    }}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {order.customer.firstName}{" "}
                                    {order.customer.lastName}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      padding: "0.2",
                                      margin: "0 !important",
                                    }}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {order.pickup}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      padding: "0.2",
                                      margin: "0 !important",
                                    }}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {moment(order.orderDate).format(
                                      "YYYY-MM-DD hh:mm"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      padding: "0.2",
                                      margin: "0 !important",
                                    }}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {!order.orderPrice
                                      ? "N/A"
                                      : order.orderPrice &&
                                        order.status === 10 &&
                                        order.orderPrice.actualValue > 0
                                      ? "₦" +
                                        order.orderPrice.actualValue
                                          .toFixed(2)
                                          .toString()
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      : order.orderPrice &&
                                        order.orderPrice.actualValue === 0
                                      ? `${
                                          "₦" +
                                          order.orderPrice.maxValue
                                            .toFixed(2)
                                            .toString()
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )
                                        }`
                                      : `${
                                          "₦" +
                                          order.orderPrice.maxValue
                                            .toFixed(2)
                                            .toString()
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )
                                        }`}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      padding: "0.2",
                                      margin: "0 !important",
                                    }}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {paymentType(order.paymentType)}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      padding: "0.2",
                                      margin: "0 !important",
                                    }}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {orderStatus(order.status)}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      padding: "0 !important",
                                      margin: "0 !important",
                                    }}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    <button
                                      className="btn btn-sm btn-primary"
                                      onClick={() => {
                                        selectOrder(order);
                                      }}
                                      style={{ fontWeight: 600 }}
                                    >
                                      View
                                    </button>
                                  </TableCell>
                                </TableRow>
                              ))}
                              {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                  <TableCell colSpan={6} />
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    ) : (
                      <div className="text-center mx-auto">
                        <p className="text-Center">
                          No Order available currently
                        </p>
                      </div>
                    )}
                  </div>
                  <div>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        50,
                        { label: "All", value: -1 },
                      ]}
                      component="div"
                      count={filteredOrders().length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="orderDetails1"
        className="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenteredScrollableTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-scrollable modal-dialog-centered"
          role="document"
        >
          <Dialog
            open={openViewDetail}
            keepMounted
            onClose={handleCloseViewDetail}
            fullWidth={true}
            maxWidth="lg"
          >
            <div className="modal-content">
              <div className="modal-header">
                <div className="row d-block">
                  <h5
                    className="modal-title"
                    id="exampleModalCenteredScrollableTitle"
                  >
                    Order details
                  </h5>
                  <ul
                    className="nav nav-tabs mt-2 mb-0 pb-0"
                    id="myTab-1"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#order"
                        role="tab"
                        aria-controls="order"
                        aria-selected="true"
                      >
                        Order
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="profile-tab"
                        data-toggle="tab"
                        href="#recipient"
                        role="tab"
                        aria-controls="recipient"
                        aria-selected="false"
                      >
                        Destination
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="contact-tab"
                        data-toggle="tab"
                        href="#rider"
                        role="tab"
                        aria-controls="rider"
                        aria-selected="false"
                      >
                        Rider
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="logs-tab"
                        data-toggle="tab"
                        href="#logs"
                        role="tab"
                        aria-controls="logs"
                        aria-selected="false"
                      >
                        Logs
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="order-price-tab"
                        data-toggle="tab"
                        href="#orderPrice"
                        role="tab"
                        aria-controls="order-price"
                        aria-selected="true"
                      >
                        Price Breakdown
                      </a>
                    </li>
                  </ul>
                </div>
                <button type="button" className="close" aria-label="Close">
                  <span aria-hidden="true" onClick={handleCloseViewDetail}>
                    ×
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="tab-content" id="myTabContent-2">
                  <div
                    className="tab-pane fade show active"
                    id="order"
                    role="tabpanel"
                    aria-labelledby="order-tab"
                  >
                    <div className="media align-items-start">
                      <img
                        src="assets/images/user/delivery-box.png"
                        className="img-fluid avatar-rounded avatar-65"
                        alt="user"
                      />
                      <div className="media-body ml-2">
                        <div className="d-flex justify-content-between">
                          <p className="mb-0 mt-1">{selectedOrder.orderNo}</p>
                          <span className="btn-group btn-group-toggle btn-group-edges">
                            {/* <button
                                                            className="button btn btn-sm button-icon bg-primary"
                                                        >
                                                            Notify Partner
                                                        </button>
                                                        <button
                                                            className="button btn btn-sm button-icon bg-secondary"
                                                        >
                                                            Cancel
                                                        </button> */}
                            {cancelOrders(selectedOrder.status)}
                            {/* {isLoading === 'loading' &&
                                                            <button
                                                            className="button btn btn-sm button-icon bg-secondary"
                                                            disabled
                                                        >
                                                             <i className="fas fa-spinner fa-spin"></i>
                                                        </button>
                                                        } */}
                          </span>
                        </div>
                        <span className="font-weight-bold">
                          {/* {selectedOrder &&
                                                        selectedOrder.vehicleType &&
                                                        selectedOrder
                                                            .vehicleType.name} */}
                          {selectedOrder?.vehicleType?.name}
                          &nbsp; (
                          {selectedOrder &&
                            selectedOrder.vehicleSize &&
                            selectedOrder.vehicleSize.name}
                          )
                        </span>
                      </div>
                    </div>
                    <div className="media align-items-start mt-4">
                      <div className="media-body">
                        <p className="text mb-0">Pickup Location</p>
                        <span className="font-weight-bold d-block">
                          {selectedOrder.pickup}
                        </span>
                      </div>
                    </div>

                    {/* <div className="media align-items-start mt-4">
                                            <div className="media-body">
                                                <div className="row mb-3">
                                                    <div className="col-md-3 bordered">
                                                        <a
                                                            href="assets/images/Orders/gold-watches.jpg"
                                                            target="_blank"
                                                        >
                                                            <img
                                                                src="assets/images/Orders/gold-watches.jpg"
                                                                alt="watch"
                                                                width="80"
                                                                height="80"
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <a
                                                            href="assets/images/Orders/necklace.jpg"
                                                            target="_blank"
                                                        >
                                                            <img
                                                                src="assets/images/Orders/necklace.jpg"
                                                                alt="watch"
                                                                width="80"
                                                                height="80"
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <a
                                                            href="assets/images/Orders/shoes.jpg"
                                                            target="_blank"
                                                        >
                                                            <img
                                                                src="assets/images/Orders/shoes.jpg"
                                                                alt="watch"
                                                                width="80"
                                                                height="80"
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <a
                                                            href="assets/images/Orders/necklace2.jpg"
                                                            target="_blank"
                                                        >
                                                            <img
                                                                src="assets/images/Orders/necklace2.jpg"
                                                                alt="watch"
                                                                width="80"
                                                                height="80"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                    <div className="media align-items-start mt-4">
                      <div className="media-body">
                        <p className="text mb-0">Pickup Date</p>
                        <span className="font-weight-bold d-block">
                          {/* { 
                                                        format(new Date(selectedOrder.orderDate), 'yyyy-MM-dd HH:mm')
                                                    } */}
                          {moment(selectedOrder.orderDate).format(
                            "YYYY-MM-DD HH:mm"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="media align-items-start mt-4">
                      <div className="media-body">
                        <p className="text mb-0">Order Amount</p>
                        <span className="font-weight-bold d-block">
                          {!selectedOrder.orderPrice
                            ? "N/A"
                            : estimatedValue(selectedOrder.status)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </span>
                      </div>
                    </div>
                    <div className="media align-items-start mt-4">
                      <div className="media-body">
                        <p className="text mb-0">Estimated Distance</p>
                        <span className="font-weight-bold d-block">
                          {selectedOrder.estimatedDistance / 1000} km
                        </span>
                      </div>
                    </div>
                    <div className="media align-items-start mt-4">
                      <div className="media-body">
                        <p className="text mb-0">Estimated Time</p>
                        <span className="font-weight-bold d-block">
                          {/* { selectedOrder.estimatedTime / 60 } mins */}
                          {Math.ceil(estimatedTime)}
                          mins
                        </span>
                      </div>
                    </div>
                    <hr className="font-weight-bold" />
                    <h6>Customer Information</h6>
                    <div className="media align-items-start mt-4">
                      <div className="media-body">
                        <p className="text mb-0">Customer Name</p>
                        <span className="font-weight-bold d-block">
                          {selectedOrder &&
                            selectedOrder.customer &&
                            selectedOrder.customer.firstName}
                          &nbsp;
                          {selectedOrder &&
                            selectedOrder.customer &&
                            selectedOrder.customer.lastName}
                        </span>
                      </div>
                    </div>
                    <div className="media align-items-start mt-4">
                      <div className="media-body">
                        <p className="text mb-0">Customer Location</p>
                        <span className="font-weight-bold d-block">
                          {selectedOrder &&
                            selectedOrder.customer &&
                            selectedOrder.customer.address}
                        </span>
                      </div>
                    </div>
                    <div className="media align-items-start mt-4">
                      <div className="media-body">
                        <p className="text mb-0">Customer Phone Number</p>
                        <span className="font-weight-bold d-block">
                          {selectedOrder &&
                            selectedOrder.customer &&
                            selectedOrder.customer.phoneNumber}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade show"
                    id="recipient"
                    role="tabpanel"
                    aria-labelledby="recipient-tab"
                  >
                    <Table aria-labelledby="tableTitle">
                      <TableHead>
                        <TableRow>
                          <TableCell>Destination</TableCell>
                          <TableCell>Recipient Name</TableCell>
                          <TableCell>Phone Number</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Distance</TableCell>
                          <TableCell>Est. Time</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody tabindex={-1}>
                        {selectedOrder.destinationPoints &&
                          selectedOrder.destinationPoints.length > 0 &&
                          selectedOrder.destinationPoints.map(
                            (destinationPoint, index) => (
                              <TableRow
                                // hover
                                tabindex={-1}
                                key={index}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                  {destinationPoint.destination}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                  {destinationPoint.recipientName}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                  {destinationPoint.recipientPhone}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                  {destinationPoint.description}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    width: "5% important",
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                  {destinationPoint.estimatedDistance / 1000}km
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    width: "5% important",
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                  {Math.floor(
                                    destinationPoint.estimatedTime / 60
                                  )}{" "}
                                  mins
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    width: "5% important",
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                  {destinationStatus(destinationPoint.status)}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    width: "5% important",
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                  {destinationPoint.imageUrl ? (
                                    <a
                                      className=""
                                      href={destinationPoint.imageUrl}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View image
                                    </a>
                                  ) : (
                                    <span class=""></span>
                                  )}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                      </TableBody>
                    </Table>
                  </div>

                  <div
                    className="tab-pane fade show"
                    id="rider"
                    role="tabpanel"
                    aria-labelledby="rider-tab"
                  >
                    <div className="media align-items-start mt-2">
                      <div className="media-body">
                        <p className="text mb-0">Name</p>
                        <span className="font-weight-bold d-block">
                          {selectedOrder &&
                            selectedOrder.orderRider &&
                            selectedOrder.orderRider.driver &&
                            selectedOrder.orderRider.driver.rider &&
                            selectedOrder.orderRider.driver.rider.firstName}
                          &nbsp;
                          {selectedOrder &&
                            selectedOrder.orderRider &&
                            selectedOrder.orderRider.driver &&
                            selectedOrder.orderRider.driver.rider &&
                            selectedOrder.orderRider.driver.rider.lastName}
                        </span>
                      </div>
                    </div>
                    <div className="media align-items-start mt-4">
                      <div className="media-body">
                        <p className="text mb-0">Phone Number</p>
                        <span className="font-weight-bold d-block">
                          {selectedOrder &&
                            selectedOrder.orderRider &&
                            selectedOrder.orderRider.driver &&
                            selectedOrder.orderRider.driver.rider &&
                            selectedOrder.orderRider.driver.rider.telephone}
                        </span>
                      </div>
                    </div>
                    <div className="media align-items-start mt-4">
                      <div className="media-body">
                        <p className="text mb-0">Vehicle Name</p>
                        <span className="font-weight-bold d-block">
                          {selectedOrder &&
                            selectedOrder.orderRider &&
                            selectedOrder.orderRider.driver &&
                            selectedOrder.orderRider.driver.vehicle &&
                            selectedOrder.orderRider.driver.vehicle
                              .vehicleBrand &&
                            selectedOrder.orderRider.driver.vehicle.vehicleBrand
                              .name}
                          &nbsp;
                          {selectedOrder &&
                            selectedOrder.orderRider &&
                            selectedOrder.orderRider.driver &&
                            selectedOrder.orderRider.driver.vehicle &&
                            selectedOrder.orderRider.driver.vehicle.model &&
                            selectedOrder.orderRider.driver.vehicle.model.name}
                        </span>
                      </div>
                    </div>
                    <div className="media align-items-start mt-4">
                      <div className="media-body">
                        <p className="text mb-0">Vehicle Number</p>
                        <span className="font-weight-bold d-block">
                          {selectedOrder &&
                            selectedOrder.orderRider &&
                            selectedOrder.orderRider.driver &&
                            selectedOrder.orderRider.driver.vehicle &&
                            selectedOrder.orderRider.driver.vehicle.regNo}
                        </span>
                      </div>
                    </div>
                    <div className="media align-items-start mt-4">
                      <div className="media-body">
                        <p className="text mb-0">Company Name</p>
                        <span className="font-weight-bold d-block">
                          {selectedOrder &&
                            selectedOrder.orderRider &&
                            selectedOrder.orderRider.driver &&
                            selectedOrder.orderRider.driver.rider &&
                            selectedOrder.orderRider.driver.rider.partner &&
                            selectedOrder.orderRider.driver.rider.partner
                              .companyName}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade show"
                    id="logs"
                    role="tabpanel"
                    aria-labelledby="order-logs"
                  >
                    <Table aria-labelledby="tableTitle">
                      <TableHead>
                        <TableRow>
                          <TableCell>Time</TableCell>
                          <TableCell>Message</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody tabindex={-1}>
                        {selectedOrder.orderLogs &&
                          selectedOrder.orderLogs.length > 0 &&
                          selectedOrder.orderLogs.map((orderLog, index) => (
                            <TableRow
                              // hover
                              tabindex={-1}
                              key={index}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  padding: "0.5",
                                  margin: "0 !important",
                                }}
                              >
                                {moment(orderLog.time).format("HH:mm:ss")}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  padding: "0.5",
                                  margin: "0 !important",
                                }}
                              >
                                {orderLog.name}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </div>

                  <div
                    className="tab-pane fade show"
                    id="orderPrice"
                    role="tabpanel"
                    aria-labelledby="order-price-tab"
                  >
                    <div className="media align-items-start mb-4">
                      <div className="media-body">
                        <p className="text mb-0">Total Amount</p>
                        <span className="font-weight-bold d-block">
                          &#8358;
                          {selectedOrder?.orderPrice?.actualValue
                            ?.toFixed(2)
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="media align-items-start mt-2">
                          <div className="media-body">
                            <p className="text mb-0">Base Fare</p>
                            <span className="font-weight-bold d-block">
                              &#8358;
                              {selectedOrder?.orderPriceBreakDown?.baseFare
                                ?.toFixed(2)
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="media align-items-start mt-2">
                          <div className="media-body">
                            <p className="text mb-0">Booking Fare</p>
                            <span className="font-weight-bold d-block">
                              &#8358;
                              {selectedOrder?.orderPriceBreakDown?.bookingFare
                                ?.toFixed(2)
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="media align-items-start mt-2">
                          <div className="media-body">
                            <p className="text mb-0">Distance Fare</p>
                            <span className="font-weight-bold d-block">
                              &#8358;
                              {selectedOrder?.orderPriceBreakDown?.distanceFare
                                ?.toFixed(2)
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="media align-items-start mt-2">
                          <div className="media-body">
                            <p className="text mb-0">Time Fare</p>
                            <span className="font-weight-bold d-block">
                              &#8358;
                              {selectedOrder?.orderPriceBreakDown?.timeFare
                                ?.toFixed(2)
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="media align-items-start mt-2">
                          <div className="media-body">
                            <p className="text mb-0">Waiting Fare</p>
                            <span className="font-weight-bold d-block">
                              &#8358;
                              {selectedOrder?.orderPriceBreakDown?.waitingFare
                                ?.toFixed(2)
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="media align-items-start mt-2">
                          <div className="media-body">
                            <p className="text mb-0">Other Fare</p>
                            <span className="font-weight-bold d-block">
                              &#8358;
                              {selectedOrder?.orderPriceBreakDown?.otherFare
                                ?.toFixed(2)
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="media align-items-start mt-2">
                          <div className="media-body">
                            <p className="text mb-0">Tax</p>
                            <span className="font-weight-bold d-block">
                              &#8358;
                              {selectedOrder?.orderPriceBreakDown?.tax
                                ?.toFixed(2)
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="media align-items-start mt-2">
                          <div className="media-body">
                            <p className="text mb-0">Cash on Delivery</p>
                            <span className="font-weight-bold d-block">
                              &#8358;
                              {selectedOrder?.destinationPoints
                                ?.map((c) => c.cashonDelivery)
                                ?.reduce((b, a) => b + a, 0)
                                ?.toFixed(2)
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-4"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
          <ToastContainer />
        </div>
      </div>
    </>
  );
};

export default OrderList;
